// Generated by Framer (a1b5076)

import { addFonts, cx, CycleVariantState, getFonts, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Calendly from "https://framerusercontent.com/modules/WIJbzyan03eQVbqqCNqQ/ZVFGE0CIYem9dXbVNw5j/Calendly.js";
const CalendlyFonts = getFonts(Calendly);

const cycleOrder = ["Y6UP0RmF5"];

const variantClassNames = {Y6UP0RmF5: "framer-v-1xk2y39"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "Y6UP0RmF5", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-wJsW4", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-1xk2y39", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"Y6UP0RmF5"} ref={ref} style={{...style}}><motion.div className={"framer-yvtqq9-container"} layoutDependency={layoutDependency} layoutId={"EWZef8sFo-container"}><Calendly calendar={"start-divorce-com"} height={"100%"} hideGdprBanner hideLandingPageDetails={false} id={"EWZef8sFo"} layoutId={"EWZef8sFo"} style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div><motion.div className={"framer-2iujtm-container"} layoutDependency={layoutDependency} layoutId={"GalPhV5Gb-container"}><Calendly calendar={"start-divorce-com"} height={"100%"} hideGdprBanner hideLandingPageDetails={false} id={"GalPhV5Gb"} layoutId={"GalPhV5Gb"} style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-wJsW4 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-wJsW4 .framer-dn7q2c { display: block; }", ".framer-wJsW4 .framer-1xk2y39 { height: 600px; overflow: hidden; position: relative; width: 500px; }", ".framer-wJsW4 .framer-yvtqq9-container, .framer-wJsW4 .framer-2iujtm-container { bottom: 0px; flex: none; left: 0px; position: absolute; right: 0px; top: 0px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 600
 * @framerIntrinsicWidth 500
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 */
const FramerskkqvOr_J: React.ComponentType<Props> = withCSS(Component, css, "framer-wJsW4") as typeof Component;
export default FramerskkqvOr_J;

FramerskkqvOr_J.displayName = "Calendly 15 minute widget";

FramerskkqvOr_J.defaultProps = {height: 600, width: 500};

addFonts(FramerskkqvOr_J, [...CalendlyFonts])